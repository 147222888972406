<template>
  <div class="body">
    <div class="vod_success_img">
      <img height="15%" src="../assets/external/vod_login_success.png" width="15%" />
    </div>
    <div class="vod_success_text">{{data}}</div>
    <button class="vod_success_button" @click="close">关闭</button>
  </div>
</template>
<script>
export default {
  name: "paymentPage",
  data() {
    return {
      data: "支付成功！"
    };
  },
  created() {
    this.__init();
  },
  methods: {
    __init() {
      let tips = this.getQueryString("tips");
      if (tips) {
        this.data = decodeURI(tips);
      }
    },
    close() {
      try {
        var ua = navigator.userAgent.toLowerCase(); //前用户所使用的是什么浏览器
        if (ua.indexOf("micromessenger") != -1) {
          //判断是否是微信打开的
          WeixinJSBridge.call("closeWindow");
        } else if (ua.indexOf("alipay") != -1) {
          //判断是支付宝打开的
          AlipayJSBridge.call("exitApp"); // 支付宝 (退出当前H5应用)
        } else {
          window.close();
        }
      } catch (e) {
        window.close();
      }
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    }
  }
};
</script>

<style scope>
.body {
  width: 100%;
  height: 100%;
  background: url("../assets/external/vod_login_bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 220%;
  background-attachment: fixed;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: center;
}

.vod_success_img {
  margin-top: 40%;
}

.vod_success_text {
  margin-top: 5%;
  font-weight: bold;
  color: white;
  font-size: 16px;
}

.vod_success_button {
  margin-top: 0px;
  width: 200px;
  height: 35px;
  line-height: 35px;
  background-color: white;
  color: rgb(112, 92, 151);
  font-size: 16px;
  border-radius: 50px;
  margin: 40% auto;
}
</style>
